.booking-basket {
  &__item {
    border-bottom: $colour-brand 2px solid;
    p {
      font-size: calculateRem(15px);
    }
    &__price {
      
    }
    &__remove {
      background-color: transparent;
      border: none;
      color: $colour-brand;
    }
  }
  &__total {
    font-size: 1.7rem;
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
    }
  }
  &__coupon {
    &__wrapper {
      // background-image: url("#{$public-url}desktop/images/pages/basket/golden-ticket-bg.jpg");
      // background-size: cover;
      // background-position: center;
      background-color: $body-colour;
    }
    &__title {
      font-size: calculateRem(18px);
      font-weight: 600;
    }
    &__notifier {
      * {
        color: #fff;;
        font-size: 0.9rem;
      }
      &--success {
        background-color: green;
      }
      &--error {
        background-color: red;
      }
    }
  }

  &__payment {
    label {
      color: #fff;
      text-transform: capitalize;
    }
  }
} // booking-basket END

.mini-basket {
  $basket: &;
  position: absolute;
  right: 0;
  cursor: pointer;
  &, * {
    transition: none;
  }
  
  &__text {
    // font-family: $title-font--reg;
  }
  &__item {
    $__item: &;
    background-color: $colour-brand;
    font-size: calculateRem(15px);
    width: 370px;
    &[aria-expanded] {
      width: 175px;
    }
    &[aria-expanded="true"]{
      width: 370px;
    }
    &, * {
      color: #fff;
    }
  }
  &__items {
    &, &__item {
      width: 370px;
    }
    &__item {
      background-color: #272727;
      &:nth-child(even) {
        background-color: #151515;
      }
      &__name {
        font-size: calculateRem(18px);
      }
      &__info {
        font-size: calculateRem(14px);
      }
      &__price {
        font-size: calculateRem(20px);
      }
    }
  }
  .btn {
    @include transition($btn-trans-speed);
  }
} // mini-basket END

.booking-modal-text {
  font-size: calculateRem(15px);
}