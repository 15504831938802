// https://codepen.io/jamesbarnett/pen/vlpkh
/****** Style Star Rating Widget *****/

.rating { 
  border: none;
  float: left;
  label {
    margin-bottom: 0;
  }
}

.rating > input { display: none; } 

.rating > label { 
  margin: 5px;
  font-size: 1.3em !important;
}

.rating > label { 
  color: #cecece; 
 float: right; 
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { color: $colour-brand;  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: $colour-brand;  } 