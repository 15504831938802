// main: ../style.scss

// ------------------------
// -- URLS
// ------------------------;
$public-url: 'https://s3-eu-west-1.amazonaws.com/cdn.nottinghamdriving.com/live/';
// $public-url: 'https://d1ng9ruhars7bh.cloudfront.net/live/';
// $images-url: '#{$public-url}/combined/fonts/';
// $font-url: '/build/site/fonts/';
$font-url: '../../../fonts/';

// ------------------------
// -- Global
// ------------------------
$global-margin: 15px;
$global-padding: 15px;
// $global-line-height: 1.3;
$global-line-height: 1.473;
$global-radius: 5px;

// ------------------------
// -- General
// ------------------------

// ------------------------
// -- Transition Speed
// ------------------------
$btn-trans-speed: 0.5s;

// ------------------------
// -- Font Size
// ------------------------
// $banner-size: calculateRem(48px);
// $body-font-size: #{calculateRem(13px)}; // 14px
// $h1-size: calculateRem(48px);
// $h2-size: calculateRem(30px);
// $h3-size: calculateRem(25px);
$banner-font-size: 3.75rem;
// $body-font-size: 0.8125rem;
$body-font-size: 1.125rem;
$h1-size: 2.5rem;
$h2-size: $h1-size;
$h3-size: 2rem;
$h4-size: 1.5rem;

$footer-font-size: $body-font-size;

// ------------------------
// -- Font Family
// ------------------------
// $fall-back-font: "Helvetica Neue", Helvetica, Arial, sans-serif;
$fall-back-font: "robotomedium", Arial, Helvetica, sans-serif;
$title-font-reg: "robotoregular", $fall-back-font;
$title-font-bold: "robotobold", $fall-back-font;
$title-font-black: "robotoblack", $fall-back-font;
// $title-font-bold: "bebas_neue_bold", $fall-back-font;
// $title-font-alt: "harbour_brushregular", $fall-back-font;

$body-font: $fall-back-font;

$h1-font-family: $title-font-bold;
$h2-font-family: $title-font-bold;
$h3-font-family: $title-font-bold;

// ------------------------
// -- Colours
// ------------------------
$colour-brand:         #ed1f24;
$colour-brand-dark: #1c1d1d;
$body-colour:     #0d0d0d;
// $colour-brand-two:     #984907;
// $colour-brand-two-dark:    darken($colour-brand-two, %);
// $colour-grey:    #272727;
// $colour-grey-dark:    #151515;
// $colour-grey-light:    #ccc;

$body-font-colour: #fff;
$title-colour: $colour-brand;

//Social Colours
$facebook-colour: #3b5998;
$twitter-colour: #55acee;
$googleplus-colour: #dd4b39;
$pinterest-colour: #cb2027;
$instagram-colour: #125688;
$youtube-colour: #bb0000;
$linkedin-colour: #007bb5;
$rss-colour: #ff6600;
