// $bookingColourAvailable: #00b300;
// $bookingColourLastChance: #ff5300;
// $bookingColourUnavailable: #db0600;

// $bookingColourAvailable: #609480;
// $bookingColourLastChance: #DEAF43;
// $bookingColourUnavailable: #DE4A43;

$bookingColourAvailable: #4A9527;
$bookingColourSelected: #E49836;
$bookingColourLastChance: #E49836;
$bookingColourUnavailable: #0283f6;
$bookingColourFullyBooked: #CE1212;

.booking-calendar {
	&__bg {
		&-fully-booked {
			background-color: $bookingColourFullyBooked;
		}
		&-unavailable {
			background-color: $bookingColourUnavailable;
		}
		&-available {
			background-color: $bookingColourAvailable;
		}
	}
	&__heading {
		background-color: $colour-brand-dark;
		padding: 15px 0;
		@include box-shadow(1);
	}
	.title {
		display: block;
		text-align: center;
		font-size: calculateRem(24px);
	}
	&__options {
		background-color: #1c1d1d;
	}
	&__package {
		font-family: $title-font-bold;
		font-size: calculateRem(18px);
		color: #fff;
		&, * {
			cursor: pointer;
		}
		&:hover, &.selected {
			background-color: $bookingColourAvailable;
		}
	}
	&__timeslots {
		&__slot {
			font-family: $title-font-bold;
			font-size: calculateRem(18px);
			color: $bookingColourAvailable;
			&, * {
				cursor: pointer;
			}
			&:hover, &.selected {
				color: #fff;
				background-color: $bookingColourAvailable;
			}
		}
		&__help {
			a {
				color: $colour-brand;
			}
		}
		&__please-note {
			p {
				text-decoration: underline;
			}
		}
	} 
	&__help, &__total {
		background-color: #fff;
		span, p {
			color: #000;
		}
		a {
			color: $colour-brand;
		}
	}
	&__chosen-date {
		font-size: calculateRem(18px) !important;
		color: $bookingColourAvailable;
	}
	&__help {
		&__title {
			font-size: calculateRem(18px) !important;
		}
		p, a {
			font-size: calculateRem(13px);
		}
	}
	&__total {
		.title {
			font-size: calculateRem(20px);
		}
	}
	&__calendar {
		// background-image: url('#{$public-url}desktop/images/framework/content-bg.jpg');
		// background-repeat: repeat;

		.bg {
			&-success {
				background-color: $bookingColourAvailable !important;
			}

			&-warning {
				background-color: $bookingColourLastChance !important;
			}

			&-danger {
				background-color: $bookingColourUnavailable !important;
			}
		}

		button {
			&:focus {
				outline: 0;
			}
		}

		// colour options
		.colour {
			&-available {
				color: $bookingColourAvailable;
			}

			&-unavailable {
				color: $bookingColourUnavailable;
				text-decoration: none !important;
			}
			&-fully-booked {
				color: $bookingColourFullyBooked;
				text-decoration: none !important;
			}
		}

		.title {
			color: $colour-brand-dark;
		}

		&__heading {
			background-color: $colour-brand-dark;
			padding: 15px 0;
			text-align: center;
			@include box-shadow(1);

			.title {
				font-size: calculateRem(30px);
			}
		}

		&__calendar {}

		&__timeslots {
			background-color: $colour-brand-dark;

			&__slot {
				font-family: $title-font-bold;
				font-size: calculateRem(23px);
				color: $bookingColourAvailable;
				cursor: pointer;
			}

			&__help {
				a {
					color: $colour-brand-dark;
				}
			}

			&__please-note {
				p {
					text-decoration: underline;
				}
			}

			&__loading {
				color: #fff;
				font-size: 25px;
			}
		}

		// __timeslots END
		.react-calendar {
			background-image: url('#{$public-url}desktop/images/framework/content-bg.jpg');
			background-image: image-set(
				url('#{$public-url}desktop/images/framework/content-bg.webp') 1x,
				url('#{$public-url}desktop/images/framework/content-bg.jpg') 1x
			);
			background-repeat: repeat;
			// background-color: #151515;
			// padding: 0 15px 40px 15px;
			padding: 15px 0;

			&__navigation {
				margin-bottom: 10px;
				text-align: center;

				button {
					background-color: transparent;
					border: none;
					color: $colour-brand-dark;
					text-transform: uppercase;
					padding: 0 15px;
					@include media-breakpoint-down(sm) {
						padding: 0 10px;
					}
					// font-size: calculateRem(28px);
				}

				&__label {
					font-family: $title-font-bold;
					font-size: calculateRem(26px);

					@include media-breakpoint-down(sm) {
						font-size: calculateRem(16px);
					}
				}

				&__arrow {
					font-size: calculateRem(30px);
					@include media-breakpoint-down(sm) {
						font-size: calculateRem(25px);
					}
				}

				&__prev {
					&2 {}
				}

				&__next {
					&2 {}
				}
			}

			&__month-view {
				text-align: center;

				&__weekdays {
					&__weekday {
						font-family: $title-font-bold;

						&,
						abbr {
							pointer-events: none !important;
							text-decoration: none !important;
							// cursor: not-allowed;
						}

						abbr {
							color: $colour-brand-dark;
							font-size: calculateRem(24px);

							@include media-breakpoint-down(sm) {
								font-size: calculateRem(20px);
							}
						}
					}
				}

				// __weekdays END
				&__days {
					&__day {
						background-color: transparent;
						border: none;
						font-family: $title-font-bold;
						font-size: calculateRem(24px);
						color: $bookingColourAvailable;
						padding: 15px 0;

						@include media-breakpoint-down(md) {
							font-size: calculateRem(22px);
							padding: 5px 0;
						}
						
						@include media-breakpoint-down(sm) {
							font-size: calculateRem(18px);
							padding: 7px 0;
						}

						// @include transition-option(color, 0.3, linear);
						&:hover {
							// color: #fff;
							color: #000;
						}

						// day is disabled because its a previous datered
						&:disabled {
							abbr {
								color: #a5a5a5 !important;

							}
						}

						// room has now available time slots - so turn red
						&.date-full {
							abbr {
								color: $bookingColourFullyBooked !important;
							}
						}
						&.date-disabled {
							abbr {
								color: $bookingColourUnavailable !important;
							}
						}

						// this is the date text in the middle
						abbr {}
					}
				}

				// __days END
			}

			&__tile {
				&--active {
					position: relative;

					abbr {}

					&:after {
						content: '';
						display: block;
						height: 60px;
						width: 60px;
						border: $bookingColourAvailable dashed 3px;
						@include border-radius(50%);
						@include vh-align();

						@include media-breakpoint-down(sm) {
							height: 40px;
							width: 40px;
						}

						// line-height: 53px
					}
					&.hide-active {
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
	&__key {
		background-color: $colour-brand-dark;

		.title {
			display: inline-block;
		}

		&__title, &__status {
			color: #fff !important;
		}

		&__title {
			@include media-breakpoint-down(sm) {
				font-size: calculateRem(25px);
			}
		}

		&__block {
			display: inline-block;
			width: 30px;
			height: 30px;

			@include media-breakpoint-down(sm) {
				width: 20px;
				height: 20px;
			}

			&--available {
				background-color: $bookingColourAvailable;
			}

			&--booked {
				background-color: $bookingColourFullyBooked;
			}

			&--unavailable {
				background-color: $bookingColourUnavailable;
			}
		}

		&__status {
			position: relative;
			top: -5px;

			@include media-breakpoint-down(lg) {
				font-size: calculateRem(20px) !important;
			}
		}
	}
} // booking-calendar END