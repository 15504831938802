// main: ../style.scss

// ie fallbacks
@mixin ieHeightFix($size) {
  height: calculateRemPX($size);
  height: #{$size}rem;
}
@mixin ieWidthFix($size) {
  width: calculateRemPX($size);
  width: #{$size}rem;
}

@mixin greyscale($amount) {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale($amount); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale($amount); /* Microsoft Edge and Firefox 35+ */
}

@mixin decoration-colour($colour) {
  -webkit-text-decoration-color: $colour;
          text-decoration-color: $colour;
}

// Fixes animation flicker
@mixin backface() {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin float-full() {
  float: left;
  width: 100%;
  display: block;
}

// Project Specific
@mixin box-shadow($amount) {
  -webkit-box-shadow: 23px 30px 32px -20px rgba(0,0,0,$amount);
  box-shadow: 23px 30px 32px -20px rgba(0,0,0,$amount);
}

// ----------------------
// Box Sizing
// ----------------------
@mixin box-sizing() {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

// ----------------------
// Border Radius
// ----------------------
@mixin border-radius($radius1, $radius2, $radius3, $radius4) {
  border-radius: $radius1, $radius2, $radius3, $radius4;
}
@mixin border-radius($radius) {
  border-radius: $radius;
}

// ----------------------
// Transitions
// ----------------------
@mixin transition ($trans-duration) {
-webkit-transition: all $trans-duration;
     -o-transition: all $trans-duration;
        transition: all $trans-duration;
}
@mixin transition-option ($type, $trans-duration, $option) {
-webkit-transition: $type $trans-duration $option;
     -o-transition: $type $trans-duration $option;
        transition: $type $trans-duration $option;
}

// ----------------------
// Opacity
// ----------------------
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// ----------------------
// Background Colour Hex
// ----------------------
@mixin bg-opacity($colour, $opacity) {
  $rgba: rgba($colour, $opacity);
  $ie-hex-str: ie-hex-str($rgba);
  background-color: transparent; // Fallback
  background-color: $rgba;
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
  zoom: 1;
}

@mixin bg-cover($url, $position) {
  background-image: url('#{$url}');
  background-size: cover;
  background-position: $position;
}
// ----------------------
// Button Animation
// ----------------------
@mixin button-bg($bg) {
  background-color: $bg;
    &:hover {
      background-color: darken($bg, 8%);
           -webkit-transition: all $btn-trans-speed;
                -o-transition: all $btn-trans-speed;
                   transition: all $btn-trans-speed;
    }
}

// ----------------------
// Clearfix
// ----------------------
@mixin clearfix() {
  &:after {
    display:block;
    clear:both;
    content:'';
  }
}

// ----------------------
// Aligning
// ----------------------
@mixin vh-align() {
  position: absolute;
  top: 50%;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);

  // this is my old way of centering(not good for ie or edge) - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/13475965/
  // transform: translate(-50%,-50%);
	// -webkit-transform: translate(-50%,-50%);
	// -moz-transform: translate(-50%,-50%);
	// -o-transform: translate(-50%,-50%);
	// -ms-transform: translate(-50%,-50%);
}
@mixin h-align() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
}

@mixin v-align() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

@mixin align-center() {
  position: absolute;
  top: 50%;

  left: 50%;
  margin-left: auto;
  margin-right: auto;

  transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

// ----------------------
// Transform
// ----------------------
@mixin rotate($value) {
  transform: rotate($value);
	-webkit-transform: rotate($value);
	-moz-transform: rotate($value);
	-o-transform: rotate($value);
	-ms-transform: rotate($value);
}

// ----------------------
// Transform
// ----------------------
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder {@content}
  ::-moz-placeholder {@content}
  :-ms-input-placeholder {@content}
}

// ----------------------
// Columns - Used to put ULs into columns
// ----------------------
@mixin columns($count, $gap) {
  -moz-column-count: $count;
  -moz-column-gap: $gap;
  -webkit-column-count: $count;
  -webkit-column-gap: $gap;
  column-count: $count;
  column-gap: $gap;
}

// ----------------------
// Flex Box
// ----------------------
@mixin flexbox() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// ----------------------
// Transforms
// ----------------------
@mixin transform($type, $amount) {
  -webkit-transform: $type($amount);
  -ms-transform: $type($amount);
  transform: $type($amount);
}

// ----------------------
// Transform Removal
// ----------------------
@mixin no-transform() {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

// ----------------------
// Input Placeholder Styling
// ----------------------
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


// Keyframes - http://joshbroton.com/quick-fix-sass-mixins-for-css-keyframe-animations/

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
      @content;
  }
  @-webkit-keyframes #{$animationName}
  @keyframes #{$animationName} {
      @content;
  }
}
