// main: ../header.scss;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 1155px,
//   xl: 1340px
// )

// -- variables;
$modal-content-bg: #000;
$close-color: #ed1f24;
$close-text-shadow: 'none';
$modal-header-padding: 1rem  1rem  0  1rem;
$modal-content-border-width: 0;
$modal-xl: 940px;
$modal-md: 650px;

// -- form overide
$input-height: 35px;

// -- tooltip
$tooltip-bg: #904200;
$tooltip-font-size: 11px;


// -- grid
// $grid-gutter-width: 15px !default;
$grid-gutter-width: 20px !default;


// spacers
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375)  //150px
  ),
  $spacers
);