
.contact-form, .standard-form {
	input, textarea {
		&::placeholder {
			text-transform: uppercase;
		}
	}
	&__newsletter {
		.form-check {
			padding: 0;
		}
		label {
			font-size: 1rem;
			color: #fff;
			&::before {
				content: '';
				height: 15px;
				width: 15px;
				display: inline-block;
				background-color: red;
				margin: 0 15px -2px 0;
				@include transition(0.3s);
				text-shadow:0 0 15px rgba(0,0,0,0.5);
				box-shadow: inset 0 0 15px 0 rgba(0,0,0,0.5);
			}
		}
		input {
			display: none;
			&:checked + label:before {
				background-color: green;
			}
		}
	}
	&__travel-dates {
		&__icon {
			background-color: #fff;
			i {
				font-size: 25px;
				color: #000;
			}
		}
	}
} // contact-form END
.review-form, .booking-form {
	label, p {
		color: #fff;
		font-size: 13px;
	}
} // review-form END

.loading-spinner {
	&__eating {
		display: block;
		height: 40px;
	}
}