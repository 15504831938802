@font-face {
    font-family: 'robotoblack';
    src: url('#{$font-url}roboto/roboto-black-webfont.eot');
    src: url('#{$font-url}roboto/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-url}roboto/roboto-black-webfont.woff2') format('woff2'),
         url('#{$font-url}roboto/roboto-black-webfont.woff') format('woff'),
         url('#{$font-url}roboto/roboto-black-webfont.ttf') format('truetype'),
         url('#{$font-url}roboto/roboto-black-webfont.svg#robotoblack') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotobold';
    src: url('#{$font-url}roboto/roboto-bold-webfont.eot');
    src: url('#{$font-url}roboto/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-url}roboto/roboto-bold-webfont.woff2') format('woff2'),
         url('#{$font-url}roboto/roboto-bold-webfont.woff') format('woff'),
         url('#{$font-url}roboto/roboto-bold-webfont.ttf') format('truetype'),
         url('#{$font-url}roboto/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotoregular';
    src: url('#{$font-url}roboto/roboto-regular-webfont.eot');
    src: url('#{$font-url}roboto/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-url}roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$font-url}roboto/roboto-regular-webfont.woff') format('woff'),
         url('#{$font-url}roboto/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$font-url}roboto/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'robotomedium';
    src: url('#{$font-url}roboto/roboto-medium-webfont.eot');
    src: url('#{$font-url}roboto/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-url}roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('#{$font-url}roboto/roboto-medium-webfont.woff') format('woff'),
         url('#{$font-url}roboto/roboto-medium-webfont.ttf') format('truetype'),
         url('#{$font-url}roboto/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}