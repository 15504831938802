.activity-slider {
	position: relative;
	z-index: 1;
	overflow: hidden;
	&__single {
		&__wrapper {
			display: block;
			position: relative;
		}
		&__title {
			// @include h-align();
			position: absolute;
			top: 90px;
			left: 0;
			width: 100%;
			@include media-breakpoint-down(md) {
				top: 30px;
			}
		}
		.btn {
			@include h-align();
			bottom: 90px;
			@include media-breakpoint-down(md) {
				bottom: 30px;
			}
		}
	}
}

.splide {
	&__slider {
		outline: 0 !important;
	}
	&__arrow {
		background: none;
		border: none;
		outline: 0 !important;
		&, * {
			color: $colour-brand;
			filter: invert(20%) sepia(74%) saturate(3315%)  brightness(95%) contrast(102%);
		}
		&--prev, &--next {
			@include v-align;
			position: absolute;
			z-index: 1;
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		&--prev {
			@include rotate(180deg);
			left: -100px;
			@include media-breakpoint-down(lg) {
				left: -40px;
			}
		}
		&--next {
			right: -100px;
			@include media-breakpoint-down(lg) {
				right: -40px;
			}
		}
	}
}