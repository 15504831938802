.product-block {
    &__single {
        $__single: &;
        position: relative;
        display: block;
        &__title {
            z-index: 2;
            width: 100%;
            font-size: $h3-size;
            @include media-breakpoint-down(lg) {
                font-size: $h3-size * 0.9;
            }
            @include media-breakpoint-up(md) {
                position: absolute;
                left: 0;
                bottom: 15px;
                
            }
            @include media-breakpoint-down(sm) {
                font-size: $h3-size * 0.65;
                background-color: $colour-brand;
                @include align-center();
                max-width: calc(100% - 15px);
                // height: fit-content;
                width: fit-content;
                // width: auto;
            }
        }
        &__hidden {
            $__single__hover: &;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            @include bg-opacity(#000, 0.5);
            opacity: 0;
            @include transition-option(opacity, 0.5s, ease);
            @at-root #{$__single}:hover {
                #{$__single__hover} {
                    @include media-breakpoint-up(lg) {
                        opacity: 1;
                    }
                }
            }
        }
    }
} // product-block END