.offering-cta {
	&__text {
		background-color: #1c1d1d;
		&__title {
			color: $colour-brand;
			font-family: $title-font-black !important;
		}
	}
	&__hover {
		$__hover: &;
		background-color: #1c1d1d;
		&__link {
			$__hover__link: &;
			border: $colour-brand 3px solid;
			@include transition-option(background-color, 0.5s, ease);
			// @include media-breakpoint-down(md) {
			// 	font-size: calculateRem(25px);
			// }
			@at-root #{$__hover}:hover {
				#{$__hover__link} {
					background-color: $colour-brand;
				}
			}
		}
	}
	&__img-link {
		$__img-link: &;
		display: block;
		position: relative;
		&__title {
			$__img-link__title: &;
			position: absolute;
			left: 15px;
			bottom: 15px;
			@include transition-option(color, 1s, ease);
			// @include media-breakpoint-down(md) {
			// 	font-size: calculateRem(35px);
			// }
			// @include media-breakpoint-down(sm) {
			// 	font-size: calculateRem(30px);
			// }
			// @include media-breakpoint-down(xs) {
			// 	font-size: calculateRem(25px);
			// }
			@at-root #{$__img-link}:hover {
				#{$__img-link__title} {@media screen {
					color: $colour-brand;
				}}
			}
		}
	}
} // offering-cta END