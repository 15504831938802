// $footer-text-grey: #5b5b5b;
$footer-text-grey: #9c9c9c;

.main-footer {
	p, a {
		font-size: calculateRem(13px);
	}
	&__nav {
		$main-footer__nav: &;
		&__link {
			$main-footer__nav__link: &;
			@at-root #{$main-footer__nav}--alt {
				#{$main-footer__nav__link} {
					color: $footer-text-grey;
					&:hover {
						color: $colour-brand;
					}
				}
			}
		}
		&--border {
			@include media-breakpoint-up(md) {
				border-left: #5b5b5b 4px solid;
			}
		}
	}
	&__copyright {
		span {
			color: $footer-text-grey;
		}
	}
	&__social {
		a {
			font-size: 1.4rem;
		}
	}
} // main-footer END