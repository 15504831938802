input, textarea, select, option {
  color: #000 !important;
  border: none !important;
  border-radius: 0 !important;
  // &::placeholder {
  //   font-size: 13px;
  // }
}

label {
  &.form-check-label {
    &, * {
      font-size: 0.8125rem;
    }
  }
}

label.error, .error-message, .pristine-error {
  color: red !important;
  font-size: 0.8125rem;
}

input {
  &.error {
    border: red 2px solid;
  }

  // https://stackoverflow.com/a/34385597/13168586
  // fixes issue on date inputs placeholder not showing on ios devices
  @include media-breakpoint-down(sm) {
    &[type='date']:before {
      content: attr(placeholder);
      color: #5b5b5b !important;
      text-transform: uppercase;
    }
    &[type='date']:focus::before, &[type='date']:valid::before {
      content: "" !important;
    }
  }
}

#newsletterForm {
  input {
    background-color: #3a3a3a;
    color: #fff !important;
    height: 44px;
    &::placeholder {
      color: #5b5b5b;
    }
  }
}

#basketForm {
  select {
    font-family: $title-font-bold;
    color: #000;
    font-size: calculateRem(18px);
  }
}

.StripeElement {
  background-color: #fff;
  padding: 8px 15px;
}