.review-block {
	background-color: #fff;
	&__title, &__text {
		color: #000;
	}
	&__title {

	}
	&__text {
		color: #000;
		font-size: 14px;
	}
	&__rating {
		i {
			color: $colour-brand;
			font-size: 20px;
		}
	}
} // review-block-